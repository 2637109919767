import React, { useEffect, useRef } from 'react';

import { Modal } from '@hagerty/react-components';

import usePurchaseFlowOptimizationAssets from '../usePurchaseFlowOptimizationAssets';
import styles from './WelcomeModal.module.scss';

export const WelcomeModal: React.FC = () => {
  const params = new URLSearchParams(location.search);
  const { modal } = usePurchaseFlowOptimizationAssets();
  const ref = useRef(null);

  useEffect(() => {
    if (params.get('show_modal') === 'true') ref?.current.click();
    const listener = (event: any) => {
      if (event.target.classList.contains('modal__container')) handleClose();
    };
    ref?.current.nextSibling.addEventListener('click', listener);
    return () => {
      ref?.current.nextSibling.removeEventListener('click', listener);
    };
  }, [ref]);

  const handleClose = () => {
    ref?.current.nextSibling.click();
  };

  return (
    <div className={styles.welcome}>
      <div id="welcome-modal" ref={ref}></div>
      <Modal id="welcome-modal" isAnimated>
        <img src={modal.images[0].url} alt={modal.images[0].alt} />
        <h2 data-cy="welcome-modal-title">{modal.title['text-0']}</h2>
        <p className={`color-dark-1 description`} data-cy="welcome-modal-description">
          <span>{modal.description['text-0']}</span>
          <strong>{modal.description['strong-1']}</strong>
          <span>{modal.description['text-2']}</span>
        </p>
        <button className="button button__gradient" data-cy="welcome-modal-button" onClick={handleClose}>
          {modal.action['text-0']}
        </button>
      </Modal>
    </div>
  );
};
