import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { parseImages, parseTokenType } from 'src/helpers/parseMarkdown';

const usePurchaseFlowOptimizationAssets = () => {
  const { t, ready } = useTranslation();

  return useMemo(() => {
    return {
      modal: {
        title: parseTokenType(t('purchase-flow-optimization.modal.title')),
        description: parseTokenType(t('purchase-flow-optimization.modal.description')),
        images: parseImages(t('purchase-flow-optimization.modal.images')),
        action: parseTokenType(t('purchase-flow-optimization.modal.action')),
      },
    };
  }, [ready]);
};

export default usePurchaseFlowOptimizationAssets;
